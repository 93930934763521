import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-curculum',
  templateUrl: './curculum.component.html',
  styleUrls: ['./curculum.component.scss']
})
export class CurculumComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
