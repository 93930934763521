<section class="invitation partial-padding">
    <label>Hi! I’m Tomasz 👋</label>
    <div class="description">
        <span>Javascript Developer, Front-End Engineer and Desinger based in 📍 Wrocław Poland.</span>
    </div>
    <div class="bio">
        <span>I am an open-source enthusiast and independent developer from Poland. On the website you will find information about technologies that I know. Description of my projects that I have created and in which I participated. I am currently working on my own Digital Digger application - a
            standalone desktop application - a sample library for music producers.</span>
    </div>
    <div class="description" style="padding-bottom: 13px;">
        <span>My Stack 🛠️</span>
    </div>
    <section class="techno">
        <div class="chip"><span>JavaScript</span></div>
        <div class="chip"><span>TypeScript</span></div>
        <div class="chip"><span>Angular</span></div>
        <div class="chip"><span>Electron</span></div>
        <div class="chip"><span>NodeJs</span></div>
        <div class="chip"><span>C#</span></div>
        <div class="chip"><span>.Net</span></div>
        <div class="chip"><span>Git</span></div>
        <div class="chip"><span>Photoshop</span></div>
    </section>
</section>