import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'app-pro-section',
  templateUrl: './pro-section.component.html',
  styleUrls: ['./pro-section.component.scss']
})
export class ProSectionComponent implements OnInit {
  public window: any;
  constructor(@Inject(DOCUMENT) private document: Document) {
    this.window = this.document.defaultView;
  }

  ngOnInit(): void {
    this.window.oncontextmenu = function (event: any) {
      event.preventDefault();
      event.stopPropagation();
      return false;
    };
  }

}
