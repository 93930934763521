import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DigitalDiggerProjectComponent } from './digital-digger-project/digital-digger-project.component';

const routes: Routes = [
  { path: '', redirectTo: '/bio', pathMatch: 'full' },
  {
    path: "digitaldigger",
    component: DigitalDiggerProjectComponent
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, anchorScrolling: 'enabled', })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
