import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Location, LocationStrategy } from '@angular/common';
import { fromEvent } from 'rxjs';
import { faAngleLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { AppService } from './app.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'tomaszchlebek.dev';
  public faAngleLeft = faAngleLeft;
  public hideSidebar: boolean = false;

  constructor(public appservice: AppService, public router: Router) {
    this.router.events.subscribe(routerEvent => {
      if (routerEvent instanceof NavigationStart) {
        if (routerEvent.url == "/") {
          this.router.navigate(["bio"], { skipLocationChange: true });
        }
      }
    });
  }
  ngOnInit() {
  }

}
