import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {


  constructor(
    private breakpointObserver: BreakpointObserver,
    public appservice: AppService,
    private router: Router
  ) { }

  ngOnInit() {
    this.router.events.subscribe((routerEvent) => {
      if (routerEvent instanceof NavigationEnd) {
        this.checkAvatarVisibility(routerEvent.url);
      }
    });


    this.breakpointObserver
      .observe(['(max-width: 1000px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          console.log('500');
          this.appservice.isMobile = true;
          this.appservice.hideAvatar = true;
        } else {
          this.appservice.isMobile = false;
          this.appservice.hideAvatar = false;
        }
      });
    this.breakpointObserver
      .observe(['(min-width: 1000px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          console.log('500');
          this.appservice.isMobile = false;
          this.appservice.hideAvatar = false;
        this.checkAvatarVisibility(this.router.url);
        }
      });
  }
  private checkAvatarVisibility(url: string) {
    if (url === '/pro') {
      this.appservice.hideAvatar = true;
    }
  }
}
