import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { SocialMediaComponent } from './social-media/social-media.component';
import { DigitalDiggerProjectComponent } from './digital-digger-project/digital-digger-project.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterModule } from '@angular/router';
import { HomeSectionComponent } from './home/partials/home-section/home-section.component';
import { HomeRoutingModule } from './home/home.routing';
import { ProSectionComponent } from './home/partials/pro-section/pro-section.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CurculumComponent } from './home/partials/curculum/curculum.component';
import { LayoutModule } from '@angular/cdk/layout';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SocialMediaComponent,
    DigitalDiggerProjectComponent,
    HomeSectionComponent,
    ProSectionComponent,
    CurculumComponent
  ],
  imports: [
    AppRoutingModule,
    RouterModule,
    HomeRoutingModule,
    BrowserModule,
    FontAwesomeModule,
    TooltipModule.forRoot(),
    LayoutModule

  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
