<!-- <div class="wrapper">
    <section class="main-section">
        <router-outlet></router-outlet>
    </section>
</div> -->

<div class="wrapper">
    <section class="main-section">
        <div class="partial">
            <!-- [style.height]="appservice.hideAvatar == true ? '709.5px' : '390px'" -->
            <div class="inner-section content-section content-padding full-h-content"
                [style.width]="appservice.hideAvatar == true ? '100%' : ''">
                <div class="partial logo-section">
                    <ul class="menu">
                        <li [routerLink]="'/bio'" [skipLocationChange]="true">Bio</li>
                        <li [routerLink]="'/pro'" [skipLocationChange]="true">Projects</li>
                        <li [routerLink]="'/cv'" [skipLocationChange]="true">CV</li>
                    </ul>
                    <div class="inner-section logo-section bread" *ngIf="appservice.hideAvatar">
                    </div>
                </div>

                <router-outlet style="display: none;"></router-outlet>
                <div class="social">
                    <app-social-media></app-social-media>
                </div>
            </div>
            <div class="inner-section img-section avatar-section" >
                <picture *ngIf="!appservice.hideAvatar || appservice.isMobile">
                    <source media="(min-width: 1000px)" srcset="../../assets/img/avatar/avatar.png">
                    <source media="(max-width: 1000px)" srcset="../../assets/img/avatar/avatar_mobile.png">

                    <source srcset="../../assets/img/avatar.png">
                    <img class="avatar" src="../../assets/img/avatar.png" alt="avatar">
                </picture>
                <!-- <div class="avatar" *ngIf="appservice.isMobile" ><svg width="94" height="84" viewBox="0 0 94 84" fill="rgb(128, 128, 128)"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M67.301 83.498H10.107C9.18298 83.498 8.43498 82.75 8.43498 81.826V30.452C3.44398 28.759 0.914978 25.985 0.914978 22.187C0.914978 12.59 17.515 5.07501 38.701 5.07501C59.891 5.07501 76.493 12.591 76.493 22.187C76.493 25.981 73.964 28.759 68.973 30.452V81.827C68.975 82.75 68.227 83.498 67.301 83.498ZM11.78 80.152H65.629V29.22C65.629 28.471 66.126 27.815 66.844 27.609C72.494 26.007 73.147 23.75 73.147 22.187C73.147 15.538 59.306 8.42101 38.703 8.42101C18.1 8.42101 4.26298 15.538 4.26298 22.187C4.26298 23.75 4.91598 26.011 10.566 27.609C11.286 27.815 11.782 28.471 11.782 29.22V80.152H11.78Z"
                            fill="rgb(128, 128, 128)"></path>
                        <path
                            d="M67.301 83.498C66.692 83.498 66.1 83.158 65.805 82.574C65.393 81.747 65.727 80.744 66.553 80.329L74.219 76.496V24.923C74.219 24.174 74.715 23.518 75.435 23.312C81.084 21.714 81.739 19.453 81.739 17.891C81.739 11.241 67.901 4.12401 47.298 4.12401C36.143 4.12401 25.574 6.39502 19.029 10.202C18.232 10.66 17.206 10.395 16.742 9.59402C16.277 8.79702 16.55 7.77401 17.349 7.30701C24.486 3.15701 35.4 0.778015 47.297 0.778015C68.485 0.778015 85.084 8.29302 85.084 17.891C85.084 21.688 82.555 24.462 77.564 26.155V77.53C77.564 78.163 77.206 78.741 76.64 79.026L68.049 83.323C67.811 83.443 67.554 83.498 67.301 83.498Z"
                            fill="rgb(128, 128, 128)"></path>
                        <path
                            d="M83.411 73.883C82.486 73.883 81.738 73.134 81.738 72.209V67.607C81.738 66.682 82.486 65.934 83.411 65.934C84.335 65.934 85.084 66.682 85.084 67.607V72.209C85.084 73.134 84.335 73.883 83.411 73.883Z"
                            fill="rgb(128, 128, 128)"></path>
                        <path
                            d="M83.411 62.589C82.486 62.589 81.738 61.84 81.738 60.916V29.128C81.738 28.425 82.176 27.798 82.833 27.556C90.034 24.912 89.741 18.426 89.725 18.152C89.671 17.234 90.369 16.44 91.287 16.381C92.266 16.316 93 17.008 93.064 17.927C93.274 20.953 91.829 27.213 85.085 30.253V60.917C85.084 61.84 84.335 62.589 83.411 62.589Z"
                            fill="rgb(128, 128, 128)"></path>
                        <path
                            d="M43.319 67.817C42.702 67.817 42.11 67.473 41.819 66.882C41.411 66.052 41.752 65.052 42.581 64.645C44.208 63.843 49.967 58.446 50.47 56.495C50.019 56.393 48.778 56.321 45.853 57.024C32.89 60.131 28.58 60.271 27.352 57.609C26.381 55.499 27.78 52.743 37.726 45.547C44.392 40.728 46.392 38.479 46.994 37.548C45.716 37.404 41.831 37.675 30.554 41.064C26.963 42.146 23.484 42.838 22.349 40.591C21.832 39.568 20.269 36.453 38.62 22.768C39.354 22.209 40.404 22.359 40.959 23.108C41.512 23.85 41.361 24.896 40.62 25.448C34.307 30.159 27.424 36.156 25.784 38.715C26.457 38.66 27.627 38.454 29.594 37.862C45.384 33.111 49.158 33.549 50.322 35.917C51.368 38.032 49.957 40.835 39.688 48.26C33.069 53.05 31.193 55.226 30.661 56.095C31.902 56.308 35.402 56.089 45.074 53.769C48.531 52.936 52.649 52.345 53.678 55.195C55.224 59.471 45.343 67.013 44.063 67.643C43.82 67.763 43.568 67.817 43.319 67.817Z"
                            fill="rgb(128, 128, 128)"></path>
                    </svg></div> -->
            </div>
        </div>

    </section>
</div>