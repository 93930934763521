import { Location, LocationStrategy } from '@angular/common';
import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  public hideSidebar: boolean = false;
  public hideAvatar: boolean = false;
  public isMobile: boolean = false;

  constructor(location: Location, public router: Router, loc: LocationStrategy) {
    router.events.subscribe(routerEvent => {
      if (routerEvent instanceof NavigationStart) {
        if (routerEvent.url == '/pro') {
          this.hideAvatar = true;
        }
        else {
          if (this.isMobile) {

            this.hideAvatar = true;
          }
          else {
            this.hideAvatar = false;
          }
        }
      }
      });
  }
}
