<div class="wrapper">
    <aside class="aside">
        <fa-icon [routerLink]="'/pro'" [skipLocationChange]="true" style="font-size:30px;cursor: pointer;" [icon]="faAngleLeft">
        </fa-icon>
        <ul class="index-page">
            <li><a (click)="scrollToElement('0')">0</a></li>
            <li><a (click)="scrollToElement('1')">1</a></li>
            <li><a (click)="scrollToElement('2')">2</a></li>
            <li><a (click)="scrollToElement('3')">3</a></li>
            <li><a (click)="scrollToElement('4')">4</a></li>
            <li><a (click)="scrollToElement('5')">5</a></li>
            <li><a (click)="scrollToElement('6')">6</a></li>
            <li><a (click)="scrollToElement('7')">7</a></li>
            <li><a (click)="scrollToElement('8')">8</a></li>
        </ul>
    </aside>
    <main class="main">
        <section id="0" class="flex-column sticky-section">
            <header>
                <label class="project-name">Digital Digger </label>
            </header>
            <article class="entry-content">
                <a class="link" href="https://digitaldigger.app" target="_blank"><span>https://digitaldigger.app</span></a>
                <p>
                    Digital Digger is dektop cross-platform standalone application for music producers.
                    It is designed to collect and catalog sound samples with an emphasis on samples from vinyl media
                    which are used to create music.You can use it to record sound,
                    download an audio file from your favorite video streaming portal, deliver samples using drag and
                    drop
                    and edit samples using the available tools.
                    No audio sources are provided with the application - it is the user's responsibility to provide
                    them.
                    Below is a brief description of all features
                </p>
            </article>
        </section>
        <br>
        <section id="1" class="digital-section">
            <div class="inner-section">
                <article class="a1">
                    <header>
                        <label class="slide-name">01.Sample List </label>
                    </header>
                    <section class="entry-content">
                        <p>
                            Sample list with pagination allow you to find intresting sound by searching and
                            additional filters data (time,sample name , oryginal name, categories).On the list is avilable sound player and options for
                            editing sample details.
                        </p>
                    </section>
                </article>
            </div>
            <div class="inner-section img-section flex-first-section-direct flex-column ">
                <picture>
                    <source media="(min-width: 2200px)" srcset="../../assets/img/sample-list/sl1.png">
                    <source media="(min-width: 1500px)" srcset="../../assets/img/sample-list/sl1@0,75x.png">
                    <source media="(min-width: 1000px)" srcset="./../assets/img/sample-list/sl_mobile_end.png">
                    <source media="(min-width: 100px)" srcset="../../assets/img/mobile-mode-all/sample-list/slMobile.png">

                    <img src="../../assets/img/sample-list/sl1.png" alt="sample-list">
                </picture>
                <picture class="only-mobile  margin-bottom">
                    <source media="(min-width: 100px)" srcset="../../assets/img/mobile-mode-all/sample-list/slMobile2.png">

                    <img src="../../assets/img/mobile-mode-all/sample-list/slMobile2@0,75x.png" alt="sample-list">
                </picture>
            </div>
        </section>
        <br>
        <br>
        <section id="2" class="digita-half-section">
            <section class="half-section flex-column">
                <div class="inner-section">
                    <article class="a1">
                        <header>
                            <label class="slide-name">02.Categories & Tags </label>
                        </header>
                        <section class="entry-content">
                            <p>
                                In Digital Digger you can add categories and subcategories to every sample. Categorize
                                every sample into their own
                                category to create your own samples’ tree structure. Additionally, the application
                                supports assigning tags to every
                                sample during the addition process. They can be further used In filtering, allowing you
                                to narrow down your search area.
                            </p>
                        </section>
                    </article>

                </div>
                <div class="inner-section flex-center img-section">
                    <picture>
                        <source media="(min-width: 1600px)" srcset="../../assets/img/category/category.png">
                        <source media="(min-width: 1001px)" srcset="../../assets/img/category/category@0,75x.png">
                        <source media="(min-width: 100px)" srcset="../../assets/img/mobile-mode-all/categories/cat.png">
                        <source srcset="../../assets/img/category/category.png">
                        <img src="../../assets/img/category/category.png" alt="sample-list">
                    </picture>
                </div>
            </section>
            <section id="3" class="half-section flex-column">
                <div class="inner-section">
                    <article class="a1">
                        <header>
                            <label class="slide-name">03.Sample Options </label>
                        </header>
                        <section class="entry-content">
                            <p>
                                Perform basic CRUD operations like adding to favourites, editing sample's fields or
                                deleting items.
                                Open selected sample from your machine’s directory or paste a link directly to the
                                streaming source.
                            </p>
                        </section>
                    </article>
                </div>
                <div class="inner-section flex-center img-section">
                    <picture>
                        <source media="(min-width: 1600px)" srcset="../../assets/img/sample-options/so.png">
                        <source media="(min-width: 1001px)" srcset="../../assets/img/sample-options/so@0,75x.png">
                        <source media="(min-width: 100px)" srcset="../../assets/img/mobile-mode-all/sample-options/so.png">
                        <source srcset="../../assets/img/sample-options/so.png">
                        <img src="../../assets/img/sample-options/so.png" alt="sample-list">
                    </picture>
                </div>
            </section>
        </section>
        <br>
        <br>
        <section id="4" class="digital-section flex-column ">
            <div class="inner-section">
                <article class="a1">
                    <header>
                        <label class="slide-name">04. Edition & Cut </label>
                    </header>
                    <section class="entry-content">
                        <p>
                            This application’s one of the main features is waveform edition. Now you can cut the best
                            parts of the songs and simply
                            save them in the library. Experiment with looping sounds with possible change of the size of
                            their region. Feel free to
                            zoom in/out audio, setting positions in manual way or by using knobs. Action buttons can
                            help you with working like
                            follow region or fit to view.
                        </p>
                    </section>
                </article>
            </div>
            <div class="inner-section flex-center margin-bottom flex-column img-section">
                <picture>
                    <source media="(min-width: 2200px)" srcset="../../assets/img/editing/ed.png">
                    <source media="(min-width: 1600px)" srcset="../../assets/img/editing/edMini.png">
                    <source media="(min-width: 1000px)" srcset="../../assets/img/editing/edMini@0,75x.png">
                    <source media="(min-width: 100px)" srcset="../../assets/img/editing/mobile/edMobile.png">
                    <source srcset="../../assets/img/recording/ed.png">
                    <img src="../../assets/img/recording/ed.png" alt="sample-list">
                </picture>
                <picture class="only-mobile">
                    <source media="(min-width: 100px)" srcset="../../assets/img/editing/mobile/edMobile1.png">
                    <img src="../../assets/img/recording/mobile/edMobile1@0,75x.png" alt="sample-list">
                </picture>
                <picture class="only-mobile">
                    <source media="(min-width: 100px)" srcset="../../assets/img/editing/mobile/edMobile2.png">
                    <img src="../../assets/img/recording/mobile/edobile2@0,75x.png" alt="sample-list">
                </picture>
                <picture class="only-mobile">
                    <source media="(min-width: 100px)" srcset="../../assets/img/editing/mobile/edMobile3.png">
                    <img src="../../assets/img/recording/mobile/edMobile3@0,75x.png" alt="sample-list">
                </picture>
            </div>
        </section>
        <br>
        <br>
        <section id="5" class="digital-section reverse">
            <div class="inner-section img-section flex-center flex-column pading-img">
                <picture>
                    <source media="(min-width: 2200px)" srcset="../../assets/img/rec/rec.png">
                    <source media="(min-width: 1500px)" srcset="../../assets/img/rec/rec@0,75x.png">
                    <source media="(min-width: 1000px)" srcset="../../assets/img/rec/mobile/recMobile@0,75x.png">
                    <source media="(min-width: 100px)" srcset="../../assets/img/mobile-mode-all/rec/rec.png">
                    <source srcset="../../assets/img/rec/rec.png ">
                    <img src="../../assets/img//rec/rec.png" alt="sample-list">
                </picture>
            </div>
            <div class="inner-section">
                <article class="a1">
                    <header>
                        <label class="slide-name">05.Recording </label>
                    </header>
                    <section class="entry-content">
                        <p>
                            Record audio from any output source device like turntable, microphone or synthesizer - edit
                            and save it in library. Use
                            the built in feature to drag and drop functions directly to wave space in Waveform Editor.
                        </p>
                    </section>
                </article>
            </div>

        </section>
        <br>
        <br>
        <section id="6" class="digital-section">
            <div class="inner-section">
                <article class="a1">
                    <header>
                        <label class="slide-name">06.Drag & Drop </label>
                    </header>
                    <section class="entry-content">
                        <p>
                            From Sample List you can drag audio file and add to library with two options to choose:
                            editing sound or save file
                            without editing . Waveform Editor have drag and drop function directly in wave space.
                        </p>
                    </section>
                </article>
            </div>
            <div class="inner-section img-section flex-center flex-column pading-img">
                <picture>
                    <source media="(min-width: 2200px)" srcset="../../assets/img/drag/drag.png">
                    <source media="(min-width: 1500px)" srcset="../../assets/img/drag/drag@0,75x.png">
                    <source media="(min-width: 1000px)" srcset=".../../assets/img/drag/mobile/dropMobile@0,75x.png">
                    <source media="(min-width: 100px)" srcset="../../assets/img/mobile-mode-all/drag/drag.png">
                    <source srcset="../../assets/img/drag/drag.png ">
                    <img src="../../assets/img/drag/drag.png" alt="sample-list">
                </picture>
            </div>
        </section>
        <br>
        <br>
        <section id="7" class="digital-section reverse">
            <div class="inner-section img-section flex-center flex-column pading-img">
                <picture>
                    <source media="(min-width: 2200px)" srcset="../../assets/img/progress/progres.png">
                    <source media="(min-width: 1500px)" srcset="../../assets/img/progress/progres@0,75x.png">
                    <source media="(min-width: 1000px)" srcset=".../../assets/img/progress/mobile/progresMobile@0,75x.png">
                    <source media="(min-width: 100px)" srcset="../../assets/img/mobile-mode-all/pro/pro.png">
                    <source srcset="../../assets/img/progress/progres.png ">
                    <img src="../../assets/img/progress/progres.png" alt="sample-list">
                </picture>
            </div>
            <div class="inner-section">
                <article class="a1">
                    <header>
                        <label class="slide-name">07.Audio Stream </label>
                    </header>
                    <section class="entry-content">
                        <p>
                            Feature with download video from youtube. By default editing audio is checked. Convert video
                            to mp3 file and directly
                            assign to category or cut interesting part of sample in editor.
                        </p>
                    </section>
                </article>
            </div>
        </section>
        <br>
        <br>
        <section id="8" class="digital-section">
            <div class="inner-section">
                <article class="a1">
                    <header>
                        <label class="slide-name">08.Settings </label>
                    </header>
                    <section class="entry-content">
                        <p>
                            In Setting is defined path of library directory. You can open folder, change directory and
                            move library to new place on
                            your machine.
                        </p>
                    </section>
                </article>
            </div>
            <div class="inner-section img-section flex-center flex-column pading-img">
                <picture>
                    <source media="(min-width: 2200px)" srcset="../../assets/img/settings/settings.png">
                    <source media="(min-width: 1500px)" srcset="../../assets/img/settings/settings@0,75x.png">
                    <source media="(min-width: 1000px)" srcset=".../../assets/img/settings/mobile/setMobile@0,75x.png">
                    <source media="(min-width: 100px)" srcset=".../../assets/img/mobile-mode-all/setting/set.png">
                    <source srcset="../../assets/img/settings/settings.png ">
                    <img src="../../assets/img/settings/settings.png" alt="sample-list">
                </picture>
            </div>


        </section>
        <br>
        <br>
    </main>
</div>