import { RouterModule, Routes } from "@angular/router";
import { HomeComponent } from "./home.component";
import { CurculumComponent } from "./partials/curculum/curculum.component";
import { HomeSectionComponent } from "./partials/home-section/home-section.component";
import { ProSectionComponent } from "./partials/pro-section/pro-section.component";

const routes: Routes = [
    {
        path: '',
        component: HomeComponent,
        children: [
            { path: '', redirectTo: 'bio', pathMatch: 'full' },
            {
                path: 'bio',
                component: HomeSectionComponent,
            },
            {
                path: 'pro',
                component: ProSectionComponent,
            },
            {
                path: 'cv',
                component: CurculumComponent,
            },


        ]
    }
];

export const HomeRoutingModule = RouterModule.forChild(routes);