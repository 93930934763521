import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { AppService } from '../app.service';

@Component({
  selector: 'app-digital-digger-project',
  templateUrl: './digital-digger-project.component.html',
  styleUrls: ['./digital-digger-project.component.scss']
})
export class DigitalDiggerProjectComponent implements OnInit {
  public faAngleLeft = faAngleLeft;

  constructor(public appservice: AppService, private viewportScroller: ViewportScroller) {

  }

  ngOnInit(): void { }
  
   public scrollToElement(elementId: string): void { 
      this.viewportScroller.scrollToAnchor(elementId);
  }
  
}
